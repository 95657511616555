export const usePresenter = () => {
  const authorizationData = {
    app_id: process.env.NEXT_PUBLIC_TIKTOK_CLIENT_ID ?? '',
    redirect_uri: process.env.NEXT_PUBLIC_TIKTOK_ADS_AUTH_REDIRECT_URI ?? ''
  }

  const params = new URLSearchParams(authorizationData).toString()
  const url = new URL(process.env.NEXT_PUBLIC_TIKTOK_ADS_AUTH_URL ?? '')

  url.search = params
  const redirectUrl = url.toString()

  return { redirectUrl }
}
