import { useEffect, useState } from 'react'

import { APP_ERROR_MESSAGES, APP_SUCCESS_MESSAGES } from '@/main/config'

import { ConfirmAccountPresenter, PresenterProps } from './protocols'

export const usePresenter = ({
  token,
  httpConfirmAccount
}: PresenterProps): ConfirmAccountPresenter => {
  const [loading, setLoading] = useState<boolean>(false)
  const [formError, setFormError] = useState<string | null>(null)
  const [formSuccess, setFormSuccess] = useState<string | null>(null)

  const handleConfirmAccount = () => {
    if (token) {
      setLoading(true)
      httpConfirmAccount
        .confirm({ confirmationToken: token })
        .then((response) => {
          if (response.isSuccess()) {
            setFormError(null)
            setFormSuccess(APP_SUCCESS_MESSAGES.confirm_account_ok)
          }
          if (response.isFailure()) {
            setFormSuccess(null)
            setFormError(APP_ERROR_MESSAGES[response.value.name])
          }
        })
        .catch((error) => {
          setFormSuccess(null)
          setFormError(APP_ERROR_MESSAGES[error.name])
        })
        .finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>
    if (token && !loading && !formSuccess && !formError) {
      timer = setTimeout(() => handleConfirmAccount(), 500)
    }
    return () => clearTimeout(timer)
  })

  return {
    loading,
    formError,
    formSuccess
  }
}
