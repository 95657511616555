import { TextInput } from '@brmediagroup/mis-ui-library'
import React, { useMemo } from 'react'

import { InputFormikProps } from './protocols'

export const InputFormik: React.FC<InputFormikProps> = ({
  value,
  meta,
  helpers: { setValue },
  ...props
}: InputFormikProps) => {
  const error = useMemo(
    () => (meta.touched && meta.error ? meta.error : undefined),
    [meta]
  )

  return <TextInput className="mt-3" error={error} {...props} value={value} />
}
