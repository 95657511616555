export type AppErrorMessages = Readonly<Record<string, string>>

export const APP_ERROR_MESSAGES: AppErrorMessages = {
  AccountNotConfirmedError:
    'Conta não confirmada. Verifique o e-mail de confirmação enviado.',
  BrandCreateError:
    'Erro ao criar usuário! Verifique os dados e tente novamente!',
  BrandNotFoundError:
    'Erro ao criar usuário! Verifique os dados e tente novamente!',
  BrandAlreadyExistsError:
    'Erro ao criar usuário! Verifique os dados e tente novamente!',
  InfluencerListError:
    'Não foi possível listar influenciadores. Tente novamente!',
  InfluencerNotFoundError:
    'Não foi possível encontrar o influencer. Tente novamente!',
  InvalidCredentialsError:
    'Credenciais inválidas! Verifique os dados e tente novamente!',
  InvalidTokenError: 'Token inválido. Verifique seu e-mail e tente novamente!',
  PrismaValidationError: 'Erro de validação. Verifique e tente novamente!',
  RequestSendEmailError:
    'Erro no envio de e-mail. Verifique e tente novamente!',
  RequestResetPasswordError: 'Erro ao solicitar alteração de senha.',
  ResetUserPasswordError: 'Erro de alteração de senha. Verifique os dados.',
  TeamAlreadyExistsError:
    'Erro ao criar usuário! Verifique os dados e tente novamente!',
  TeamCreateError:
    'Erro ao criar usuário! Verifique os dados e tente novamente!',
  TeamNotFoundError:
    'Erro ao criar usuário! Verifique os dados e tente novamente!',
  UnexpectedError: 'Erro inesperado. Tente novamente em instantes!',
  UniqueConstraintViolationError:
    'Registro existente. Verifique e tente novamente!',
  UserAlreadyExistsError: 'Usuário existente. Faça login para entrar.',
  UserAlreadyConfirmedError: 'Este usuário já foi confirmado.',
  UserCreateError:
    'Erro ao criar usuário! Verifique os dados e tente novamente!',
  UserNotFoundError:
    'Usuário não encontrado. Verifique os dados e tente novamente!',
  ValidatePasswordTokenError: 'Erro ao validar o token. Tente novamente!'
}

export type AppSuccessMessages = Readonly<Record<string, string>>

export const APP_SUCCESS_MESSAGES: AppSuccessMessages = {
  create_account_ok: 'Conta criada com sucesso!',
  confirm_account_request: 'Email de confirmação de conta enviado com sucesso!',
  confirm_account_ok: 'Conta confirmada com sucesso!',
  reset_password_request: 'Email de recuperação de senha enviado com sucesso!',
  reset_password_validate: 'Token de alteração de senha validado com sucesso!',
  reset_password_ok: 'Senha alterada com sucesso! Redirecionando...'
}
