import { Button, Spinner } from '@brmediagroup/mis-ui-library'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline'
import React from 'react'

import { usePresenter } from './presenter'
import { LinkTikTokBusinessAccountViewProps } from './protocols'

export const LinkTikTokBusinessAccountView: React.FC<
  LinkTikTokBusinessAccountViewProps
> = ({ brandId, isTikTokLinked, linkTikTokBusinessAccount }) => {
  const { loading, pageError, handleLinkTikTokRetry } = usePresenter({
    brandId,
    isTikTokLinked,
    linkTikTokBusinessAccount
  })

  if (pageError) {
    return (
      <div className="flex flex-col justify-center text-center h-full gap-8">
        <div>
          <div className="flex flex-col justify-center items-center">
            <XCircleIcon className="h-12 w-12 stroke-[0.6px] stroke-red-500" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              Erro na vinculação de conta!
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Dados inválidos para a vinculação de conta
            </p>
          </div>
          <div className="flex justify-center mt-6">
            <Button color="primary" onClick={handleLinkTikTokRetry}>
              Tentar novamente
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col justify-center text-center h-full gap-8">
      <div>
        {loading ? (
          <div className="flex flex-col justify-center items-center">
            <Spinner color="primary" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              Vinculando conta
            </h3>
            <p className="mt-1 text-sm text-gray-500">Aguarde um momento...</p>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center">
            <CheckCircleIcon className="h-12 w-12 stroke-[0.6px] stroke-green-500" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              Conta vinculada com sucesso!
            </h3>
            <p className="mt-1 text-sm text-gray-500">Aguarde um momento...</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default LinkTikTokBusinessAccountView
