import { Breadcrumb } from '@brmediagroup/mis-ui-library'
import Link from 'next/link'
import React from 'react'

export const TopbarContent: React.FC = () => {
  return (
    <Breadcrumb>
      <Breadcrumb.Item value="tiktok">
        <Link href="/app/tiktok">Tiktok business</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  )
}
