import { FormikHelpers, useFormik } from 'formik'
import { signIn } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { APP_ERROR_MESSAGES } from '@/main/config/messages'
import { useFormikForm } from '@/presentation/hooks'

import { PresenterProps, SignInFormFields, SignInPresenter } from './protocols'

export const usePresenter = ({
  validationSchema
}: PresenterProps): SignInPresenter<SignInFormFields> => {
  const [loading, setLoading] = useState<boolean>(false)
  const [formError, setFormError] = useState<string | null>(null)

  const router = useRouter()

  const initialValues = {
    email: '',
    password: ''
  }

  const form = useFormikForm<SignInFormFields>({
    initialValues
  })

  const resetFormErrors = () => setFormError(null)

  const handleFormSubmit = (
    { email, password }: SignInFormFields,
    formikHelpers: FormikHelpers<SignInFormFields>
  ) => {
    resetFormErrors()
    setLoading(true)
    signIn<'credentials'>('credentials', { email, password, redirect: false })
      .then((response) => {
        if (response?.ok) {
          router.replace('/app/campaigns')
          return
        }

        if (response?.status === 401) {
          setFormError(
            (response?.error && APP_ERROR_MESSAGES[response?.error]) ??
              'Usuário ou senha inválidos.'
          )
          return
        }
        setFormError(
          'Algo errado na autenticação. Tente novamente em instantes.'
        )
      })
      .catch(() => setFormError('Credenciais inválidas'))
      .finally(() => setLoading(false))
  }

  const formik = useFormik({
    initialValues: form.initialValues,
    onSubmit: handleFormSubmit,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true
  })

  const getFormikField = (field: string) => ({
    ...formik.getFieldProps(field),
    meta: formik.getFieldMeta(field),
    helpers: formik.getFieldHelpers(field)
  })

  return {
    form,
    formik,
    loading,
    handleFormSubmit,
    formError,
    getFormikField
  }
}
