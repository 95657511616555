import { FormikHelpers, useFormik } from 'formik'
import { useState } from 'react'

import { APP_ERROR_MESSAGES, APP_SUCCESS_MESSAGES } from '@/main/config'
import { useFormikForm } from '@/presentation/hooks'

import {
  ConfirmationFormFields,
  ConfirmationPresenter,
  PresenterProps
} from './protocols'

export const usePresenter = ({
  httpConfirmation,
  validationSchema
}: PresenterProps): ConfirmationPresenter<ConfirmationFormFields> => {
  const [loading, setLoading] = useState<boolean>(false)
  const [formError, setFormError] = useState<string | null>(null)
  const [formSuccess, setFormSuccess] = useState<string | null>(null)
  const initialValues: ConfirmationFormFields = {
    email: ''
  }

  const form = useFormikForm<ConfirmationFormFields>({
    initialValues
  })

  const handleConfirmation = ({ email }: ConfirmationFormFields) => {
    setLoading(true)
    httpConfirmation
      .request({ email })
      .then((response) => {
        if (response.isSuccess()) {
          setFormError(null)
          setFormSuccess(APP_SUCCESS_MESSAGES.confirm_account_request)
        }
        if (response.isFailure()) {
          setFormSuccess(null)
          setFormError(APP_ERROR_MESSAGES[response.value.name])
        }
      })
      .catch((error) => {
        setFormSuccess(null)
        setFormError(APP_ERROR_MESSAGES[error.name])
      })
      .finally(() => setLoading(false))
  }

  const handleFormSubmit = (
    { email }: ConfirmationFormFields,
    formikHelpers: FormikHelpers<ConfirmationFormFields>
  ) => {
    handleConfirmation({ email })
  }

  const formik = useFormik({
    initialValues: form.initialValues,
    onSubmit: handleFormSubmit,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true
  })

  const getFormikField = (field: string) => ({
    ...formik.getFieldProps(field),
    meta: formik.getFieldMeta(field),
    helpers: formik.getFieldHelpers(field)
  })

  return {
    form,
    formik,
    loading,
    getFormikField,
    handleFormSubmit,
    formError,
    formSuccess
  }
}
