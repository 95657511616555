import { Button, Container } from '@brmediagroup/mis-ui-library'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import Link from 'next/link'
import { SchemaOf, object, string } from 'yup'

import { InputFormik } from '@/presentation/components/form'

import { usePresenter } from './presenter'
import { SignInFormFields } from './protocols'

const signInValidationSchema: SchemaOf<SignInFormFields> = object().shape({
  email: string().email('email inválido').required('campo obrigatório'),
  password: string().min(6, 'minímo 6 caracteres').required('campo obrigatório')
})

export const SignInView: React.FC = () => {
  const {
    form,
    formError,
    getFormikField,
    formik: { handleSubmit, dirty, isValid }
  } = usePresenter({
    validationSchema: signInValidationSchema
  })

  return (
    <>
      <h1 className="text-purple-500 font-medium mb-5">Entrar</h1>
      <form className="space-y-4 w-full" onSubmit={handleSubmit}>
        <InputFormik
          {...getFormikField('email')}
          placeholder="email@email.com"
          type="email"
          name="email"
          label="Email"
        />
        <InputFormik
          {...getFormikField('password')}
          placeholder="Sua senha"
          type="password"
          name="password"
          label="Senha"
        />
        <Container className="mt-2 cursor-pointer">
          <Link href="/auth/forgot-password" passHref>
            <span className="text-purple-500 text-sm">Esqueceu a senha?</span>
          </Link>
        </Container>
        <Button
          type="submit"
          disabled={
            !form.handleCanSubmit({
              dirty,
              isValid
            })
          }
          block
        >
          Entrar
        </Button>

        {formError && (
          <div className="flex items-center space-x-2 px-6 py-2 bg-red-50 text-red-400 rounded-lg">
            <ExclamationCircleIcon className="h-4 w-4" />
            <span className="text-sm md:text-base">{formError}</span>
          </div>
        )}
        <div className="border-t-[1px] mt-10 mb-3" />
        <Container className=" flex space-x-8">
          <Link href="/auth/signup">
            <a className="text-purple-500 text-sm">Criar conta</a>
          </Link>
          <Link href="/auth/confirmation">
            <a className="text-purple-500 text-sm">Confirmar conta</a>
          </Link>
        </Container>
      </form>
    </>
  )
}
