import { Button } from '@brmediagroup/mis-ui-library'
import { CheckCircleIcon, FolderAddIcon } from '@heroicons/react/outline'
import Link from 'next/link'
import React from 'react'

import { TikTokInvertedSvg } from '@/presentation/components/ui/icon-by-provider/icons/tiktok-inverted-svg'

import { LinkStatusProps } from './protocols'

export const LinkStatusCard: React.FC<LinkStatusProps> = ({
  isTikTokLinked,
  redirectUrl
}) => {
  return (
    <div className="p-5 flex flex-col bg-white rounded-lg shadow-lg">
      <div className="flex flex-col justify-center text-center h-full gap-8">
        {isTikTokLinked ? (
          <div className="flex flex-col justify-center items-center">
            <CheckCircleIcon className="h-12 w-12 stroke-[0.6px] stroke-green-500" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              Conta vinculada
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Conta Corporativa TikTok vinculada
            </p>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center">
            <FolderAddIcon className="h-12 w-12 stroke-[0.6px] stroke-slate-400" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              Nenhuma conta vinculada
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Vincule uma Conta Corporativa TikTok
            </p>
          </div>
        )}
        <div className="flex justify-center mt-6">
          <Link href={redirectUrl} passHref>
            {isTikTokLinked ? (
              <Button color="dark" disabled>
                <TikTokInvertedSvg />
                TikTok
                <CheckCircleIcon className="stroke-green-500" />
              </Button>
            ) : (
              <Button color="dark">
                <TikTokInvertedSvg />
                Vincular TikTok
              </Button>
            )}
          </Link>
        </div>
      </div>
    </div>
  )
}
