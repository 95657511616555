import { toast } from '@brmediagroup/mis-ui-library'
import { FormikHelpers, useFormik } from 'formik'
import { useState } from 'react'

import { useFormikForm } from '@/presentation/hooks'

import {
  ForgotPasswordFormFields,
  ForgotPasswordPresenter,
  PresenterProps
} from './protocols'

export const usePresenter = ({
  httpRequestResetPassword,
  validationSchema
}: PresenterProps): ForgotPasswordPresenter<ForgotPasswordFormFields> => {
  const [loading, setLoading] = useState<boolean>(false)
  const [formError, setFormError] = useState<string | null>(null)

  const initialValues: ForgotPasswordFormFields = {
    email: ''
  }

  const form = useFormikForm<ForgotPasswordFormFields>({
    initialValues
  })

  const handleResetPassword = ({ email }: ForgotPasswordFormFields) => {
    setLoading(true)
    httpRequestResetPassword
      .request({ email })
      .then((res) => {
        toast({
          title: 'Alteração de senha',
          description: 'Confira o link enviado em seu email.',
          variant: 'success'
        })
      })
      .catch((error) => setFormError(error.message))
      .finally(() => setLoading(false))
  }

  const handleFormSubmit = (
    { email }: ForgotPasswordFormFields,
    formikHelpers: FormikHelpers<ForgotPasswordFormFields>
  ) => {
    handleResetPassword({ email })
  }

  const formik = useFormik({
    initialValues: form.initialValues,
    onSubmit: handleFormSubmit,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true
  })

  const getFormikField = (field: string) => ({
    ...formik.getFieldProps(field),
    meta: formik.getFieldMeta(field),
    helpers: formik.getFieldHelpers(field)
  })

  return {
    form,
    formik,
    loading,
    getFormikField,
    handleFormSubmit,
    formError
  }
}
