import { Button, Container } from '@brmediagroup/mis-ui-library'
import {
  CheckCircleIcon,
  ExclamationCircleIcon
} from '@heroicons/react/outline'
import Link from 'next/link'
import { IMaskInput } from 'react-imask'
import * as Yup from 'yup'

import { HttpSignUp } from '@/data/usecases/http'
import { InputFormik } from '@/presentation/components/form'

const validationSchema: Yup.SchemaOf<SignUpFormFields> = Yup.object().shape({
  email: Yup.string().email('Email inválido').required('Campo obrigatório'),
  password: Yup.string()
    .min(6, 'Minímo 6 caracteres')
    .required('Campo obrigatório'),
  brand: Yup.string().required('Campo obrigatório'),
  cnpj: Yup.string().required('Campo obrigatório'),
  cellphone: Yup.string().required('Campo obrigatório'),
  confirmation: Yup.string()
    .required('Campo obrigatório')
    .oneOf([Yup.ref('password'), null], 'Senhas devem ser iguais'),
  name: Yup.string().required('Campo obrigatório')
})

import { usePresenter } from './presenter'
import { SignUpFormFields } from './protocols'

export const SignUpView: React.FC<{ httpSignUp: HttpSignUp }> = ({
  httpSignUp
}) => {
  const {
    form,
    formik: { handleSubmit, values, handleChange, handleBlur, dirty, isValid },
    getFormikField,
    formError,
    formSuccess
  } = usePresenter({
    validationSchema,
    httpSignUp
  })

  return (
    <div>
      <h1 className="text-purple-500 font-medium mb-5">Criar conta</h1>
      <form className="space-y-4" onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-x-2">
          <div className="col-span-2">
            <InputFormik
              {...getFormikField('brand')}
              name="brand"
              label="Marca"
              placeholder="Nome da marca"
            />
          </div>
          <div className="col-span-2">
            <div className="relative flex flex-col gap-1 mt-3">
              <label className="overflow-hidden m-0 p-0 text-sm">CNPJ</label>

              <IMaskInput
                {...getFormikField('cnpj')}
                type="text"
                id="cnpj"
                label="CNPJ"
                autoComplete="cnpj"
                mask="00.000.000/0000-00"
                placeholder="CNPJ da empresa"
                className="bg-white overflow-hidden appearance-none leading-tight border border-gray-300 rounded-md focus:ring-1 focus:ring-offset-1 focus:ring-purple-200 focus:border-purple-500 placeholder:text-gray-400 "
                value={values.cnpj}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="col-span-1">
            <InputFormik
              {...getFormikField('name')}
              label="Nome"
              placeholder="Seu nome"
            />
          </div>
          <div className="col-span-1">
            <div className="relative flex flex-col gap-1 mt-3">
              <label className="overflow-hidden m-0 p-0 text-sm">Celular</label>

              <IMaskInput
                {...getFormikField('cellphone')}
                type="text"
                name="cellphone"
                id="cellphone"
                autoComplete="cellphone"
                mask="(00) 00000-0000"
                placeholder="Seu celular"
                className="bg-white overflow-hidden appearance-none leading-tight border border-gray-300 rounded-md focus:ring-1 focus:ring-offset-1 focus:ring-purple-200 focus:border-purple-500 placeholder:text-gray-400 "
                value={values.cellphone}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="col-span-2">
            <InputFormik
              {...getFormikField('email')}
              name="email"
              label="Email"
              placeholder="email@dominio.com"
            />
          </div>
          <InputFormik
            {...getFormikField('password')}
            type="password"
            label="Senha"
            placeholder="Sua senha"
          />
          <InputFormik
            {...getFormikField('confirmation')}
            type="password"
            name="confirmation"
            label="Confirme sua senha"
            placeholder="Digite a senha novamente"
          />
        </div>
        <Container className="mt-2">
          <Link href="/" passHref>
            <a className="text-purple-500 text-sm">Já possui conta?</a>
          </Link>
        </Container>
        <Button
          type="submit"
          className="mt-6"
          disabled={
            !form.handleCanSubmit({
              dirty,
              isValid
            })
          }
          block
        >
          Cadastrar
        </Button>
        {formError && (
          <div className="flex items-center space-x-2 px-6 py-2 bg-red-50 text-red-400 rounded-lg">
            <ExclamationCircleIcon className="h-4 w-4" />
            <span className="text-sm md:text-base">{formError}</span>
          </div>
        )}
        {formSuccess && (
          <div className="flex items-center space-x-2 px-6 py-2 bg-green-50 text-green-400 rounded-lg">
            <CheckCircleIcon className="h-4 w-4" />
            <span className="text-sm md:text-base">{formSuccess}</span>
          </div>
        )}
      </form>
    </div>
  )
}
