import { Button, Container } from '@brmediagroup/mis-ui-library'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import Link from 'next/link'
import React from 'react'
import * as Yup from 'yup'

import { InputFormik } from '@/presentation/components/form'
import { useRouterQuery } from '@/presentation/hooks'

import { usePresenter } from './presenter'
import { PasswordResetFormFields, PasswordResetViewProps } from './protocols'

const validationSchema: Yup.SchemaOf<PasswordResetFormFields> =
  Yup.object().shape({
    password: Yup.string()
      .min(6, 'no mínimo 6 caracteres')
      .required('Campo obrigatório'),
    confirmation: Yup.string()
      .min(6, 'no mínimo 6 caracteres')
      .required('Campo obrigatório')
  })

export const PasswordResetView: React.FC<PasswordResetViewProps> = ({
  httpResetPassword,
  httpValidatePasswordToken
}: PasswordResetViewProps) => {
  const { token } = useRouterQuery<{ token: string }>()

  const {
    form,
    formik: { dirty, getFieldProps, handleSubmit, isValid },
    getFormikField,
    invalidToken
  } = usePresenter({
    token,
    validationSchema,
    httpResetPassword,
    httpValidatePasswordToken
  })

  return (
    <>
      <form onSubmit={handleSubmit} className="space-y-4">
        <InputFormik
          {...getFormikField('password')}
          type="password"
          label="senha"
          placeholder="sua senha"
        />
        <InputFormik
          {...getFormikField('confirmation')}
          type="password"
          label="confirme sua senha"
          placeholder="digite a senha novamente"
        />
        <Container className="mt-2 cursor-pointer">
          <Link href="/" passHref>
            <span className="text-purple-500 text-sm">entrar</span>
          </Link>
        </Container>
        <Button
          type="submit"
          className="mt-6"
          disabled={!form.handleCanSubmit({ dirty, isValid })}
          block
        >
          alterar
        </Button>
        {invalidToken === true && (
          <div className="flex items-center space-x-2 px-6 py-2 bg-red-50 text-red-400 rounded-lg">
            <ExclamationCircleIcon className="h-4 w-4" />
            <span className="text-sm md:text-base">
              {'Token Inválido. Solicite novamente.'}
            </span>
          </div>
        )}
      </form>
    </>
  )
}

export default PasswordResetView
