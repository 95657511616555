import { FormikHelpers, useFormik } from 'formik'
import { useRouter } from 'next/router'
import { useState } from 'react'

import {
  APP_ERROR_MESSAGES,
  APP_SUCCESS_MESSAGES
} from '@/main/config/messages'
import { useFormikForm } from '@/presentation/hooks'

import { PresenterProps, SignUpFormFields, SignUpPresenter } from './protocols'

export const usePresenter = ({
  validationSchema,
  httpSignUp
}: PresenterProps): SignUpPresenter<SignUpFormFields> => {
  const [loading, setLoading] = useState<boolean>(false)
  const router = useRouter()
  const [formError, setFormError] = useState<string | null>(null)
  const [formSuccess, setFormSuccess] = useState<string | null>(null)

  const initialValues = {
    name: '',
    brand: '',
    cnpj: '',
    email: '',
    cellphone: '',
    password: '',
    confirmation: ''
  }

  const form = useFormikForm<SignUpFormFields>({
    initialValues
  })

  const resetFormErrors = () => setFormError(null)

  // TODO: improve cellphone field (add ddi)
  const handleSignUp = (form: SignUpFormFields) => {
    setLoading(true)
    httpSignUp
      .create({
        cellphone: form.cellphone,
        email: form.email,
        password: form.password,
        name: form.name,
        brandName: form.brand,
        cnpj: form.cnpj
      })
      .then((response) => {
        if (response.isSuccess()) {
          setFormError(null)
          setFormSuccess(APP_SUCCESS_MESSAGES.create_account_ok)
          setTimeout(() => {
            router.replace('/')
          }, 1000)
        }
        if (response.isFailure()) {
          setFormSuccess(null)
          setFormError(APP_ERROR_MESSAGES[response.value.name])
          return
        }
      })
      .catch((error) => {
        setFormSuccess(null)
        setFormError(APP_ERROR_MESSAGES[error.name])
      })
      .finally(() => setLoading(false))
  }

  const handleFormSubmit = (
    form: SignUpFormFields,
    formikHelpers: FormikHelpers<SignUpFormFields>
  ) => {
    resetFormErrors()
    handleSignUp(form)
  }

  const formik = useFormik({
    initialValues: form.initialValues,
    onSubmit: handleFormSubmit,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true
  })

  const getFormikField = (field: string) => ({
    ...formik.getFieldProps(field),
    meta: formik.getFieldMeta(field),
    helpers: formik.getFieldHelpers(field)
  })

  return {
    form,
    formik,
    loading,
    getFormikField,
    handleFormSubmit,
    formError,
    formSuccess
  }
}
