type TikTokInvertedSvgProps = {
  size?: number | string
}

export const TikTokInvertedSvg = ({ size }: TikTokInvertedSvgProps) => {
  size = size ?? '20'
  return (
    <svg
      style={{ width: size, height: size }}
      viewBox={`0 0 20 20`}
      fill="currentColor"
      id="icons"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.102 4.635a4.268 4.268 0 0 1 -0.369 -0.215 5.191 5.191 0 0 1 -0.948 -0.806c-0.708 -0.809 -0.971 -1.63 -1.068 -2.204h0.003C13.638 0.934 13.673 0.625 13.678 0.625H10.457V13.078c0 0.168 0 0.333 -0.007 0.496 0 0.021 -0.003 0.039 -0.003 0.061 0 0.009 0 0.018 -0.003 0.028 0 0.003 0 0.004 0 0.007a2.734 2.734 0 0 1 -1.376 2.17 2.688 2.688 0 0 1 -1.333 0.352c-1.5 0 -2.717 -1.223 -2.717 -2.734s1.216 -2.734 2.717 -2.734a2.691 2.691 0 0 1 0.837 0.133l0.003 -3.278a5.982 5.982 0 0 0 -4.61 1.348 6.32 6.32 0 0 0 -1.379 1.701c-0.136 0.234 -0.648 1.176 -0.711 2.704 -0.039 0.867 0.222 1.766 0.346 2.138v0.007c0.078 0.219 0.381 0.966 0.874 1.594A6.544 6.544 0 0 0 4.493 18.385v-0.007l0.007 0.007C6.059 19.445 7.788 19.375 7.788 19.375c0.299 -0.013 1.302 0 2.44 -0.539 1.262 -0.598 1.981 -1.489 1.981 -1.489a6.19 6.19 0 0 0 1.08 -1.794c0.291 -0.766 0.389 -1.685 0.389 -2.052V6.894c0.039 0.023 0.559 0.367 0.559 0.367s0.749 0.481 1.919 0.793c0.839 0.223 1.97 0.27 1.97 0.27V5.128C17.728 5.171 16.924 5.046 16.102 4.635Z" />
    </svg>
  )
}
