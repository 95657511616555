import React, { useContext, useEffect } from 'react'

import { TopbarContext } from '@/presentation/components/contexts/topbar/topbar-context'

import { TopbarContent } from './components'
import { LinkStatusCard } from './components/link-status'
import { usePresenter } from './presenter'
import { TikTokBusinessAccountViewProps } from './protocols'

export const TikTokBusinessAccountView: React.FC<
  TikTokBusinessAccountViewProps
> = ({ isTikTokLinked }) => {
  const { redirectUrl } = usePresenter()

  const { updateTopbar } = useContext(TopbarContext)

  useEffect(() => {
    updateTopbar({ content: <TopbarContent /> })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      <LinkStatusCard
        isTikTokLinked={isTikTokLinked}
        redirectUrl={redirectUrl}
      />
    </div>
  )
}
