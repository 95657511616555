import { Button, Container } from '@brmediagroup/mis-ui-library'
import Link from 'next/link'
import React from 'react'
import * as Yup from 'yup'

import { InputFormik } from '@/presentation/components/form'

import { usePresenter } from './presenter'
import { ForgotPasswordFormFields, ForgotPasswordViewProps } from './protocols'

const validationSchema: Yup.SchemaOf<ForgotPasswordFormFields> =
  Yup.object().shape({
    email: Yup.string().email('Email inválido').required('Campo obrigatório')
  })

export const ForgotPasswordView: React.FC<ForgotPasswordViewProps> = ({
  httpRequestResetPassword
}) => {
  const {
    form,
    formik: { dirty, handleSubmit, isValid },
    getFormikField
  } = usePresenter({ validationSchema, httpRequestResetPassword })

  return (
    <>
      <h1 className="text-purple-500 font-medium mb-5">
        Informe o e-mail da sua conta
      </h1>

      <form onSubmit={handleSubmit}>
        <InputFormik
          {...getFormikField('email')}
          name="email"
          label="e-mail"
          placeholder="seu@email.com"
        />

        <Container className="mt-2 cursor-pointer">
          <Link href="/" passHref>
            <span className="text-purple-500 text-sm">lembrou a senha?</span>
          </Link>
        </Container>
        <Button
          type="submit"
          className="mt-6"
          disabled={!form.handleCanSubmit({ dirty, isValid })}
          block
        >
          recuperar senha
        </Button>
      </form>
    </>
  )
}

export default ForgotPasswordView
