import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect, useState } from 'react'

import { TopbarContext } from '@/presentation/components/contexts/topbar/topbar-context'
import { useRouterQuery } from '@/presentation/hooks'

import { TopbarContent } from '../components'

import { LinkTikTokBusinessAccountViewProps } from './protocols'

export const usePresenter = ({
  brandId,
  isTikTokLinked,
  linkTikTokBusinessAccount
}: LinkTikTokBusinessAccountViewProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [pageError, setPageError] = useState<string | null>(null)
  const { auth_code: authCode } = useRouterQuery<{ auth_code: string }>()
  const { updateTopbar } = useContext(TopbarContext)
  const router = useRouter()

  useEffect(() => {
    if (!pageError && (isTikTokLinked || !authCode))
      router.replace('/app/tiktok')
  })

  useEffect(() => {
    updateTopbar({ content: <TopbarContent /> })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLinkTikTokBusinessAccount = useCallback(() => {
    if (!isTikTokLinked && !loading && authCode && brandId) {
      setLoading(true)
      linkTikTokBusinessAccount
        .request({
          authCode,
          grantType: 'authorization_code',
          brandId
        })
        .then(() => router.replace(router.asPath))
        .catch(() => setPageError('Falha ao vincular conta TikTok Business'))
        .finally(() => setLoading(false))
    }
  }, [
    authCode,
    brandId,
    isTikTokLinked,
    router,
    loading,
    linkTikTokBusinessAccount
  ])

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    timer = setTimeout(() => handleLinkTikTokBusinessAccount(), 300)

    return () => clearTimeout(timer)
  })

  const handleLinkTikTokRetry = () => router.replace('/app/tiktok')

  return { loading, pageError, handleLinkTikTokRetry }
}
