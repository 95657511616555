import { Button, Card, Container } from '@brmediagroup/mis-ui-library'
import { CheckIcon, XIcon } from '@heroicons/react/solid'
import { useRouter } from 'next/router'
import React from 'react'

import { useRouterQuery } from '@/presentation/hooks'

import { usePresenter } from './presenter'
import { ConfirmAccountViewProps } from './protocols'

export const ConfirmAccountView: React.FC<ConfirmAccountViewProps> = ({
  httpConfirmAccount
}) => {
  const { token } = useRouterQuery<{ token: string }>()
  const { push } = useRouter()
  const { loading, formError, formSuccess } = usePresenter({
    token,
    httpConfirmAccount
  })

  if (!token) {
    return null
  }

  // TODO: improve status dialog
  return (
    <Container
      flex
      alignSelf="center"
      alignItems="center"
      alignContent="center"
      height="screen"
      color="primary"
    >
      <Card
        rounded="md"
        shadow="md"
        alignContent="center"
        color="light"
        padding="xl"
        width="lg"
        className="border"
      >
        <div className="flex flex-col h-full justify-center gap-5">
          <div className="flex justify-center h-full">
            {formSuccess ? (
              <div className="flex justify-center items-center rounded-full w-10 h-10 bg-green-500">
                <CheckIcon className="w-7 h-7 text-white" />
              </div>
            ) : loading ? (
              <div className="flex justify-center items-center rounded-full w-10 h-10" />
            ) : (
              <div className="flex justify-center items-center rounded-full w-10 h-10 bg-red-500">
                <XIcon className="w-7 h-7 text-white" />
              </div>
            )}
          </div>
          <h1 className="text-purple-500 text-center font-medium mb-5">
            {formSuccess
              ? formSuccess
              : formError
              ? formError
              : 'Confirmando conta...'}
          </h1>
          <div className="h-10">
            {formSuccess ? (
              <Button type="button" onClick={() => push('/')} block>
                Entrar
              </Button>
            ) : loading ? null : (
              <Button
                type="button"
                onClick={() => push('/auth/confirmation')}
                block
              >
                Recomeçar
              </Button>
            )}
          </div>
        </div>
      </Card>
    </Container>
  )
}

export default ConfirmAccountView
