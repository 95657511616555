import { toast } from '@brmediagroup/mis-ui-library'
import { FormikHelpers, useFormik } from 'formik'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { APP_ERROR_MESSAGES, APP_SUCCESS_MESSAGES } from '@/main/config'
import { useFormikForm } from '@/presentation/hooks'

import {
  PasswordResetFormFields,
  PasswordResetPresenter,
  PresenterProps
} from './protocols'

export const usePresenter = ({
  token,
  validationSchema,
  httpValidatePasswordToken,
  httpResetPassword
}: PresenterProps): PasswordResetPresenter<PasswordResetFormFields> => {
  const [loading, setLoading] = useState<boolean>(false)
  const [formSuccess, setFormSuccess] = useState<string | null>(null)
  const [formError, setFormError] = useState<string | null>(null)
  const [userId, setUserId] = useState<string | null>(null)
  const [validateSuccess, setValidateSuccess] = useState<boolean | null>(null)

  const router = useRouter()

  const handleValidateToken = useCallback(async () => {
    if (token) {
      const valid = await httpValidatePasswordToken.validate({
        resetPasswordToken: token
      })
      setValidateSuccess(valid.isSuccess())
      if (valid.isSuccess()) setUserId(valid.value)
      if (valid.isFailure()) setUserId(null)
    }
  }, [httpValidatePasswordToken, token])

  const invalidToken = useMemo(
    () => validateSuccess === false,
    [validateSuccess]
  )

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>
    if (token && !loading && !validateSuccess) {
      timer = setTimeout(() => handleValidateToken(), 0)
    }
    return () => clearTimeout(timer)
  })

  useEffect(() => {
    if (formSuccess) setTimeout(() => router.push('/'), 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSuccess])

  const initialValues: PasswordResetFormFields = {
    password: '',
    confirmation: ''
  }

  const form = useFormikForm<PasswordResetFormFields>({
    initialValues
  })

  const handleReset = ({ password }: PasswordResetFormFields) => {
    if (userId) {
      setLoading(true)
      httpResetPassword
        .reset({ userId, newPassword: password })
        .then((response) => {
          if (response.isSuccess()) {
            toast({
              title: 'Alteração de senha',
              description: 'Senha alterada com sucesso!',
              variant: 'success'
            })
            setFormError(null)
            setFormSuccess(APP_SUCCESS_MESSAGES.reset_password_ok)
            return
          }
          if (response.isFailure()) {
            setFormSuccess(null)
            setFormError(APP_ERROR_MESSAGES[response.value.name])
          }
        })
        .catch((error) => setFormError(APP_ERROR_MESSAGES[error.name]))
        .finally(() => setLoading(false))
    }
  }

  const handleFormSubmit = (
    form: PasswordResetFormFields,
    formikHelpers: FormikHelpers<PasswordResetFormFields>
  ) => {
    handleReset(form)
  }

  const formik = useFormik({
    initialValues: form.initialValues,
    onSubmit: handleFormSubmit,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true
  })

  const getFormikField = (field: string) => ({
    ...formik.getFieldProps(field),
    meta: formik.getFieldMeta(field),
    helpers: formik.getFieldHelpers(field)
  })

  return {
    form,
    formik,
    invalidToken,
    loading,
    getFormikField,
    handleFormSubmit,
    formError,
    formSuccess,
    validateSuccess
  }
}
