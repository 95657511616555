import { Button, Container } from '@brmediagroup/mis-ui-library'
import {
  CheckCircleIcon,
  ExclamationCircleIcon
} from '@heroicons/react/outline'
import Link from 'next/link'
import React from 'react'
import * as Yup from 'yup'

import { InputFormik } from '@/presentation/components/form'

import { usePresenter } from './presenter'
import { ConfirmationFormFields, ConfirmationViewProps } from './protocols'

const validationSchema: Yup.SchemaOf<ConfirmationFormFields> =
  Yup.object().shape({
    email: Yup.string().email('Email inválido').required('Campo obrigatório')
  })

export const ConfirmationView: React.FC<ConfirmationViewProps> = ({
  httpConfirmation
}) => {
  const {
    form,
    formik: { dirty, handleSubmit, isValid },
    getFormikField,
    formError,
    formSuccess
  } = usePresenter({ httpConfirmation, validationSchema })

  return (
    <>
      <h1 className="text-purple-500 font-medium mb-5">
        Receber email de confirmação
      </h1>

      <form className="space-y-4" onSubmit={handleSubmit}>
        <InputFormik
          {...getFormikField('email')}
          name="email"
          label="Email"
          placeholder="email@dominio.com"
        />
        <Container className="mt-2">
          <Link href="/" passHref>
            <span className="text-purple-500 text-sm cursor-pointer">
              Já confirmou a conta?
            </span>
          </Link>
        </Container>
        <Button
          type="submit"
          className="mt-6"
          disabled={
            !form.handleCanSubmit({
              dirty,
              isValid
            })
          }
          block
        >
          Receber email
        </Button>
        {formError && (
          <div className="flex items-center space-x-2 px-6 py-2 bg-red-50 text-red-400 rounded-lg">
            <ExclamationCircleIcon className="h-4 w-4" />
            <span className="text-sm md:text-base">{formError}</span>
          </div>
        )}
        {formSuccess && (
          <div className="flex items-center space-x-2 px-6 py-2 bg-green-50 text-green-400 rounded-lg">
            <CheckCircleIcon className="h-4 w-4" />
            <span className="text-sm md:text-base">{formSuccess}</span>
          </div>
        )}
      </form>
    </>
  )
}

export default ConfirmationView
